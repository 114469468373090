<template>
    <modal-lateral ref="reclamos" titulo="Reclamos del cliente">
        <div class="f-14 px-4">
            <div class="row mx-0 my-2 align-items-center">
                <img class="rounded-circle mr-2 border bg-whitesmoke" width="50" height="50" src="https://i.pinimg.com/originals/e8/d7/7e/e8d77ee48131f3c0316371ea7a2e2e29.jpg" alt="" />
                <p>Diego Hernández</p>
            </div>
            <div class="row mx-0">
                <p>Último reclamo,</p>
                <p>13 Ago. 2020(Hace 78 días)</p>
            </div>
            <div class="row mx-0 my-3 justify-center">
                <img class="br-10 mr-2 border bg-whitesmoke" width="67" height="67" src="https://i.pinimg.com/originals/e8/d7/7e/e8d77ee48131f3c0316371ea7a2e2e29.jpg" alt="" />
                <div class="col my-auto">
                    <p>Vendedor Neto</p>
                    <p>Medellín, Antioquia</p>
                </div>
            </div>
            <div class="my-3">
                <div class="row mx-0">
                    Indice de reclamo
                </div>
                <div class="row mx-0 my-2">
                    <div class="col-4 mr-2 br-20 bg-gr-red text-center text-white br-20 p-2">
                        12%
                    </div>
                    <div class="col bg-gris text-center br-20 p-2">
                        Sobre 1.542 Pedidos
                    </div>
                </div>
            </div>
            <div class="my-3">
                <div class="row mx-0">
                    Índice de soporte
                </div>
                <div class="row mx-0">
                    <div class="col-4 mr-2 br-20 bg-gr-red text-center text-white br-20 p-2">
                        80%
                    </div>
                    <div class="col bg-gris text-center br-20 p-2">
                        Sobre 185 reclamos
                    </div>
                </div>
            </div>
            <div class="my-3">
                <div class="row mx-0">
                    Soportes generales
                </div>
                <div class="row mx-0">
                    <div class="col-4 br-20 bg-gr-general text-center text-white p-1 br-20 px-2">
                        10
                    </div>
                </div>
            </div>
        </div>
    </modal-lateral>
</template>

<script>
export default {
    data(){
        return {

        }
    },
    methods: {
        toggle(){
            this.$refs.reclamos.toggle()
        }
    }
}
</script>

<style lang="css" scoped>
</style>
